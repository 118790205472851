<template>
  <div id="usercomponent">
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <div style="min-height:575px">
      <el-row>
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <div class="grid-content">
            <el-table :data="jobInfo" style="width: 100%" @row-click="drawTranscriptLink">
              <el-table-column prop="jobID" label="Job ID" width="180"></el-table-column>
              <el-table-column label="Submit Date" width="180">
                <template #default="scope">
                  <p>{{ dataTransfer(scope.row.jobID) }}</p>
                </template>
              </el-table-column>
              <el-table-column label="State">
                <template #default="scope">
                  <p>{{ state[scope.row.state] }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Download Results">
                <template #default="scope">
                  <el-button @click="frontDownload(scope.row.jobID)">Download</el-button>
                  <!-- <a :href="'../../../../snippets/result/outputFiles/'+scope.row.jobID+'.txt'">Download</a> -->
                  <!-- <a :href="'./test.txt'">Download</a> -->
                  <!-- 需要写静态下载链接 -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
  import request from '@/network/request'
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import { Edit, Upload, Picture, Search, UploadFilled, Histogram, User } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'
  export default {
    name: 'LoginComponent',
    data () {
      return {
        head: "",
        jobInfo: "",
        state: {
          "1": "Success",
          "0": "Processing",
          "-1": "Failed",
        }
      }
    },
    methods: {
      dataTransfer (strT) {
        return strT.slice(-14, -10) + "_" + strT.slice(-10, -8) + "_" + strT.slice(-8, -6)
      },
      frontDownload (fileName) {
        var a = document.createElement("a"); //创建一个<a></a>标签
        a.href = this.$store.state.staticURL + fileName + ".txt"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
        a.download = fileName + ".txt"; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
        a.style.display = "none"; // 障眼法藏起来a标签
        document.body.appendChild(a); // 将a标签追加到文档对象中
        a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove(); // 一次性的，用完就删除a标签
      },
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      Edit,
      Upload,
      Picture,
      Search,
      UploadFilled,
      Histogram,
      User,
    },
    created () {
      var access = "Bearer " + this.$store.state.access
      var refresh = this.$store.state.refresh
      request({
        url: 'user/',
        headers: {
          'Authorization': access
        },
        data: {
          "refresh": refresh
        },
        method: "get"
      }).then(res => {
        this.jobInfo = res
      }).catch(err => {
        console.log(err);
        // this.refresh()
      });
    },
    mounted () {
      window.vue = this
    },
    updated () {

    },

    watch: {
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
  }
</style>